$(document).ready(function() {
    var $sidebar = $('.sidebar'),
        $window = $(window),
        $body = $('body'),
        sidebarMargin = parseInt($sidebar.css('margin'), 10),
        lastWindowPos = 0,
        windowHeight = $window.height(),
        bodyHeight = $body.height(),
        top = $window.scrollTop() == 0,
        bottom = bodyHeight == $window.scrollTop()+windowHeight;

    $(window).on('resize', function(e) {
        windowHeight = $window.height();
        bodyHeight = $body.height();
        top = $window.scrollTop() == 0;
        bottom = bodyHeight == $window.scrollTop()+windowHeight;
        $sidebar.attr( 'style', 'position: fixed; top: 0; width: '+$sidebar.outerWidth()+'px;'  );
    });

    //$(window).resize();

    $(window).on('scroll', function(e) {
        var topOffset = 0,
            sidebarHeight = $sidebar.outerHeight() + sidebarMargin,
            windowPos = $window.scrollTop();


        if ( sidebarHeight > windowHeight ) {
            if ( windowPos > lastWindowPos ) {
                if ( top ) {
                    top = false;
                    $sidebar.attr( 'style', 'position: relative' );
                } else if ( ! bottom && windowPos + windowHeight > sidebarHeight + $sidebar.offset().top && sidebarHeight < bodyHeight ) {
                    bottom = true;
                    $sidebar.attr( 'style', 'position: fixed; bottom: 0; width: '+$sidebar.outerWidth()+'px;' );
                }
            } else if ( windowPos < lastWindowPos ) {
                if ( bottom ) {
                    bottom = false;
                    topOffset = $sidebar.offset().top;
                    $sidebar.attr( 'style', 'position: relative; top: ' + topOffset + 'px;' );
                } else if ( ! top && windowPos + sidebarMargin < $sidebar.offset().top ) {
                    top = true;
                    $sidebar.attr( 'style', 'position: fixed; top: ' + topOffset + 'px; width: '+$sidebar.outerWidth()+'px;' );
                }
            } else {
                top = bottom = false;
                topOffset = ( $sidebar.offset().top > 0 ) ? $sidebar.offset().top - sidebarMargin : 0;
                $sidebar.attr( 'style', 'position: fixed; top: ' + topOffset + 'px; width: '+$sidebar.outerWidth()+'px;' );
            }
        } else {
            $sidebar.attr( 'style', 'position: fixed; top: 0; width: '+$sidebar.outerWidth()+'px;' );
        }

        lastWindowPos = windowPos;
    });
});