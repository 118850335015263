$(document).ready(function() {
    jQuery("#slider1").revolution({
        sliderType:"standard",
        sliderLayout:"auto",
        autoHeight: 'off',
        responsiveLevels: [1920, 1400, 1200, 992, 768, 460],
        gridwidth : [1426, 1024, 900, 661, 768, 460],
        gridheight : [385, 385, 385, 385, 385, 385],
        delay:9000,
        navigation: {
            bullets: {
                enable: true,
                direction: "horizontal",
                space: 16,
                hide_onleave: false,
                h_align: "center",
                v_align: "bottom",
                h_offset: 0,
                v_offset: 20
            },
            arrows:{
                style:"",
                enable:true,
                rtl:false,
                hide_onmobile:false,
                hide_onleave:true,
                hide_delay:200,
                hide_delay_mobile:1200,
                hide_under:0,
                hide_over:9999,
                tmp:'',
                left:{
                    container:"slider",
                    h_align:"left",
                    v_align:"center",
                    h_offset:20,
                    v_offset:0
                },
                right:{
                    container:"slider",
                    h_align:"right",
                    v_align:"center",
                    h_offset:20,
                    v_offset:0
                }
            },
        },
        parallax:{
            type:"mouse",
            levels:[5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85],
            origo:"slidercenter",
            speed:400,
            bgparallax:"on",
            disable_onmobile:"on"
        }
    });

    $(".fa-stack[data-toggle='tooltip']").tooltip();
});