$(document).ready(function() {
    window.mapModal = modal = $("#mapModal");

    window.initMap = function() {
        window.gMap = new google.maps.Map(document.getElementById('google-map'), {
            center: {lat: -34.397, lng: 150.644},
            zoom: 8
        });
    }

    $('.map-button').on('click', function() {
        window.mapModal.modal('show');
    });
    window.mapModal.on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget);
        window.initMap();
    });
});